const middleware = {}

middleware['checkConnection'] = require('../middleware/checkConnection.ts')
middleware['checkConnection'] = middleware['checkConnection'].default || middleware['checkConnection']

middleware['checkDevMode'] = require('../middleware/checkDevMode.ts')
middleware['checkDevMode'] = middleware['checkDevMode'].default || middleware['checkDevMode']

middleware['checkGuest'] = require('../middleware/checkGuest.ts')
middleware['checkGuest'] = middleware['checkGuest'].default || middleware['checkGuest']

middleware['checkUser'] = require('../middleware/checkUser.ts')
middleware['checkUser'] = middleware['checkUser'].default || middleware['checkUser']

export default middleware
