var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-topbar"
  }, [_c('a', {
    staticClass: "darkstore-topbar__link",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=main_banner_05_top&utm_medium=referral&utm_campaign=05")
    }
  }, [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__button"
  }, [_vm._v("\n      Перейти\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__button darkstore-topbar__button_mobile"
  })])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-topbar__logo"
  }, [_c('img', {
    staticClass: "darkstore-topbar__logo-img",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/darkstore-logo-simple.svg"),
      "alt": "darkstore logo"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "darkstore-topbar__logo-title"
  }, [_vm._v("даркстор")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "darkstore-topbar__info"
  }, [_c('div', {
    staticClass: "darkstore-topbar__title"
  }, [_c('p', {
    staticClass: "darkstore-topbar__title_full"
  }, [_vm._v("быстрая доставка продуктов")]), _vm._v(" "), _c('p', {
    staticClass: "darkstore-topbar__title_shortcut"
  }, [_vm._v("доставка за 30 минут")]), _vm._v(" "), _c('p', {
    staticClass: "darkstore-topbar__title_mobile"
  }, [_vm._v("доставка продуктов")]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__location darkstore-topbar__location_mobile"
  }, [_c('p', {
    staticClass: "darkstore-topbar__location-item"
  }, [_vm._v("Каспийск")]), _vm._v(" "), _c('p', {
    staticClass: "darkstore-topbar__location-item"
  }, [_vm._v("махачкала")])])]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__time"
  }, [_c('img', {
    staticClass: "darkstore-topbar__time-image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/time.svg"),
      "alt": "За 30 минут"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__products"
  }, [_c('img', {
    staticClass: "darkstore-topbar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/cheese.png"),
      "alt": "Сыр"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/grape.png"),
      "alt": "Виноград"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/milk.png"),
      "alt": "Молоко"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__location"
  }, [_c('p', {
    staticClass: "darkstore-topbar__location-item"
  }, [_vm._v("Каспийск")]), _vm._v(" "), _c('p', {
    staticClass: "darkstore-topbar__location-item"
  }, [_vm._v("махачкала")])]), _vm._v(" "), _c('div', {
    staticClass: "darkstore-topbar__products"
  }, [_c('img', {
    staticClass: "darkstore-topbar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/cheese.png"),
      "alt": "Сыр"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/grape.png"),
      "alt": "Виноград"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "darkstore-topbar__image",
    attrs: {
      "src": require("@/assets/images/darkstore-makhachkala-topbar/milk.png"),
      "alt": "Молоко"
    }
  })])]);
}]

export { render, staticRenderFns }