import { stringify } from 'qs'
import type { Context } from '@nuxt/types'
import { getFormData } from '@artmizu/utils'
import { ProductCartSerialize } from '~/serializer/product/ProductCart'
import PaginationSerializer from '~/serializer/Pagination'
import OrderSerializer from '~/serializer/Order'
import FilterListSerializer from '~/serializer/filter/FilterList'
import { CheckoutPaymentSeriazlier } from '~/serializer/Checkout/Payment'
import { CheckoutDeliverySeriazlier } from '~/serializer/Checkout/Delivery'
import { CheckoutCouponSerializer } from '~/serializer/Checkout/Coupon'
import { CheckoutTotalSerializer } from '~/serializer/Checkout/Total'
import { CheckoutDeliveryIntervalsSerialzier } from '~/serializer/Checkout/DeliveryIntervals'
import type OrderFeedback from '~/type/order/OrderFeedback'
import OrderCheckSerializer from '~/serializer/OrderCheck'
import type OrderCheck from '~/type/order/OrderCheck'
import type Pagination from '~/type/Pagination'
import type { NewOrder } from '~/type/NewOrder'
import { NewOrderCompleteSerializer } from '~/serializer/NewOrderComplete'

export default {
  async create(this: Context, value: NewOrder) {
    const { $axios } = this

    function courierAddress(value: NewOrder) {
      if (value.delivery?.address?.type === 'courier') {
        return JSON.stringify({
          city: value.delivery?.address?.city,
          street: value.delivery?.address?.street,
          house: value.delivery?.address?.house,
          entrance: value.delivery?.address?.entrance,
          floor: value.delivery?.address?.floor,
          apartment: value.delivery?.address?.apartment,
        })
      }
    }

    function pickupAddressID(address: NewOrder) {
      if (address.delivery.address?.type === 'pickup')
        return address.delivery.address.id
    }

    const response = await $axios.$post(
      '/api/v2/order/create',
      stringify({
        ORDER_PROP_1: value.contact?.name,
        ORDER_PROP_2: value.contact?.email,
        ORDER_PROP_7: value.delivery?.type?.allowSelectUserAddress && courierAddress(value),
        ORDER_PROP_3: value.contact?.phone,
        BUYER_STORE: value.delivery.type?.code === 'PICKUP' && pickupAddressID(value),
        DELIVERY_ID: value.delivery?.type?.id,
        PAY_SYSTEM_ID: value.payment?.type?.id,
        ORDER_DESCRIPTION: `${value.summary?.commentary}. ${(this as any).$exp?.checkoutDelivery?.$activeVariants?.[0]?.name === 'new' && (value.delivery?.deliveryDate ? `Выбрал интервал: ${value.delivery.deliveryDate}, ${value.delivery.deliveryTime}.` : 'Свяжитесь для уточнения доставки')}`,
        ORDER_BONUSES: value.summary?.bonusSpent,
        ANOTHER_PERSON_NAME: value.delivery?.recipient,
        ANOTHER_PERSON_PHONE: value.delivery?.recipientPhone,
        INTERVAL_DATE: value?.delivery.deliveryDate,
        INTERVAL_TIME: value?.delivery.deliveryTime,
      }),
    )

    return NewOrderCompleteSerializer(response)
  },

  async get(this: Context, { page, size, filter }: { page: string; size: number; filter: string }) {
    const { $axios } = this
    const result = (await $axios.$get<{
      result: {
        ITEMS: any[]
        PAGINATION: any
        FILTERS: any[]
      }
    }>(`/api/v1/user/orders?${stringify({ page, size, STATUS_ID: filter })}`))?.result

    return {
      result: {
        order: result?.ITEMS?.map((el: any) => OrderSerializer(el)) || [],
        pagination: result?.PAGINATION && PaginationSerializer(result.PAGINATION),
        filter: result?.FILTERS && FilterListSerializer(result.FILTERS),
      },
    }
  },

  async getOrderStatus(this: Context) {
    const { $axios } = this
    const result = (await $axios.$get(`/api/v1/user/orders?${stringify({ size: 1 })}`))?.result

    return {
      order: result?.ITEMS?.[0] ? OrderSerializer(result?.ITEMS[0]) : null,
    }
  },

  async getOrderData(this: Context, { location }: { location: string }) {
    const { $axios } = this
    const { result } = await $axios.$post<{ result: { PAY_SYSTEM: { LIST: any[] }; DELIVERY: { LIST: any[] }; AVAILABLE_INTERVALS: any[]; COUPON: { LIST: any[] }; BASKET: { ITEMS: any[]; TOTAL: any }; TOTAL: any } }>('/api/v2/order', stringify({ ORDER_PROP_6: location || null }))
    const productList = result?.BASKET?.ITEMS?.map(el => ProductCartSerialize(el)) || []
    const isUnavailableExist: boolean = productList?.some(el => el.status.code === 'NOT_AVAILABLE') || false
    return {
      isUnavailableExist,
      product: productList,
      deliveryIntervals: result.AVAILABLE_INTERVALS?.map(item => CheckoutDeliveryIntervalsSerialzier(item)),
      total: CheckoutTotalSerializer({ total: result.BASKET.TOTAL, list: productList, availableBonuses: result.TOTAL?.AVAILABLE_BONUSES_FOR_APPLY }),
      payment: result.PAY_SYSTEM?.LIST?.map(el => CheckoutPaymentSeriazlier(el)),
      delivery: result.DELIVERY?.LIST?.map(el => CheckoutDeliverySeriazlier(el)) || [],
      coupon: result.COUPON?.LIST?.map(el => CheckoutCouponSerializer(el)) || [],
    }
  },

  remove(this: Context, { id, reason }: { id: number; reason: string }) {
    const { $axios } = this
    return $axios.$delete(`/api/v1/order/${id}/cancel?reason=${reason}`)
  },

  addPromocode(this: Context, code: string) {
    const { $axios } = this
    return $axios.$put(`/api/v1/order/coupon/${code}`)
  },

  removePromocode(this: Context, code: string) {
    const { $axios } = this
    return $axios.$delete(`/api/v1/order/coupon/${code}`)
  },

  evaluate(this: Context, data: OrderFeedback) {
    const { $axios } = this
    return $axios.$post(
      '/api/v1/order/delivery/evaluate',
      getFormData({
        order_id: data.id,
        rate: data.rate,
        text: data.text?.length && data.text.join(),
        comment: data.comment,
        contact: data.contact ? 1 : 0,
      }),
      {
        headers: {
          post: null,
        },
      },
    )
  },

  async getOrderByHash(this: Context, { hash }: { hash: string }) {
    const { $axios } = this
    const result = (await $axios.$get(`/api/v1/order/get/${hash}`))?.result
    return { order: OrderSerializer(result) }
  },

  async getCheck(this: Context, { page, size }: { page: string; size: number }): Promise<{ result: { list: OrderCheck[]; pagination: Pagination } }> {
    const { $axios } = this
    const response = (await $axios.$get(`/api/v1/user/orders/checks?${stringify({ page, size })}`))?.result
    return {
      result: {
        list: response.ITEMS?.map((el: any) => OrderCheckSerializer(el)) || [],
        pagination: response.PAGINATION && PaginationSerializer(response.PAGINATION),
      },
    }
  },
}
