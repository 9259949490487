import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8347a32 = () => interopDefault(import('../pages/product/_id/review/create/index.vue' /* webpackChunkName: "" */))
const _4e227b1a = () => interopDefault(import('../pages/brands/promo-brand/index.vue' /* webpackChunkName: "" */))
const _8e968990 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "" */))
const _4047ff94 = () => interopDefault(import('../pages/product/_id/property.vue' /* webpackChunkName: "" */))
const _63ae0cb9 = () => interopDefault(import('../pages/product/_id/question/_questionID/index.vue' /* webpackChunkName: "" */))
const _230b7830 = () => interopDefault(import('../pages/product/_id/question/index.vue' /* webpackChunkName: "" */))
const _54effbe3 = () => interopDefault(import('../pages/product/_id/accessory.vue' /* webpackChunkName: "" */))
const _4f72a6c6 = () => interopDefault(import('../pages/product/_id/review/_reviewID/index.vue' /* webpackChunkName: "" */))
const _b9b472cc = () => interopDefault(import('../pages/product/_id/review/index.vue' /* webpackChunkName: "" */))
const _03cd7a23 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "" */))
const _23b0f4ca = () => interopDefault(import('../pages/catalog/_id.vue' /* webpackChunkName: "" */))
const _7dc990da = () => interopDefault(import('../pages/category/_id.vue' /* webpackChunkName: "" */))
const _2b2fe1e2 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "" */))
const _6f9675b8 = () => interopDefault(import('../pages/user/confirm-email/index.vue' /* webpackChunkName: "" */))
const _f4c9f3b4 = () => interopDefault(import('../pages/skidki/_id.vue' /* webpackChunkName: "" */))
const _4a93f774 = () => interopDefault(import('../pages/rassrochka/index.vue' /* webpackChunkName: "" */))
const _8e80517e = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _3b3b1430 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _5158691e = () => interopDefault(import('../pages/bonus-activation/index.vue' /* webpackChunkName: "pages/bonus-activation/index" */))
const _7c127f50 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _126ea239 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _07b969e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _0e2ca027 = () => interopDefault(import('../pages/city-select/index.vue' /* webpackChunkName: "pages/city-select/index" */))
const _1459a0a4 = () => interopDefault(import('../pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _357af589 = () => interopDefault(import('../pages/favorite/index.vue' /* webpackChunkName: "pages/favorite/index" */))
const _09a08832 = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _866533e8 = () => interopDefault(import('../pages/friday/index.vue' /* webpackChunkName: "pages/friday/index" */))
const _943e1cf0 = () => interopDefault(import('../pages/merch/index.vue' /* webpackChunkName: "pages/merch/index" */))
const _56a3593e = () => interopDefault(import('../pages/nam20/index.vue' /* webpackChunkName: "pages/nam20/index" */))
const _50af02b4 = () => interopDefault(import('../pages/newyear/index.vue' /* webpackChunkName: "pages/newyear/index" */))
const _70d092ee = () => interopDefault(import('../pages/pc-builder/index.vue' /* webpackChunkName: "pages/pc-builder/index" */))
const _753932de = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _4ed89d82 = () => interopDefault(import('../pages/quiz2024/index.vue' /* webpackChunkName: "pages/quiz2024/index" */))
const _5bc01fac = () => interopDefault(import('../pages/r1/index.vue' /* webpackChunkName: "pages/r1/index" */))
const _8db33ac8 = () => interopDefault(import('../pages/rst/index.vue' /* webpackChunkName: "pages/rst/index" */))
const _27b31a95 = () => interopDefault(import('../pages/sales/index.vue' /* webpackChunkName: "pages/sales/index" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _59fd898e = () => interopDefault(import('../pages/stuff-hunt/index.vue' /* webpackChunkName: "pages/stuff-hunt/index" */))
const _af2f0ae6 = () => interopDefault(import('../pages/success-order/index.vue' /* webpackChunkName: "pages/success-order/index" */))
const _7f799735 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _152321f8 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _27824f1e = () => interopDefault(import('../pages/user/achievements/index.vue' /* webpackChunkName: "pages/user/achievements/index" */))
const _019c98c6 = () => interopDefault(import('../pages/user/address/index.vue' /* webpackChunkName: "pages/user/address/index" */))
const _5466f3a6 = () => interopDefault(import('../pages/user/assembly-save/index.vue' /* webpackChunkName: "pages/user/assembly-save/index" */))
const _e805d63c = () => interopDefault(import('../pages/user/awaiting-review/index.vue' /* webpackChunkName: "pages/user/awaiting-review/index" */))
const _80096cf0 = () => interopDefault(import('../pages/user/bonus/index.vue' /* webpackChunkName: "pages/user/bonus/index" */))
const _fec6cf62 = () => interopDefault(import('../pages/user/history-bonus/index.vue' /* webpackChunkName: "pages/user/history-bonus/index" */))
const _bc3bd5b4 = () => interopDefault(import('../pages/user/loyalty-level/index.vue' /* webpackChunkName: "pages/user/loyalty-level/index" */))
const _d6c87c12 = () => interopDefault(import('../pages/user/order/index.vue' /* webpackChunkName: "pages/user/order/index" */))
const _4afe6832 = () => interopDefault(import('../pages/user/order-check/index.vue' /* webpackChunkName: "pages/user/order-check/index" */))
const _6ee279a5 = () => interopDefault(import('../pages/user/promocode/index.vue' /* webpackChunkName: "pages/user/promocode/index" */))
const _aa5ed5b6 = () => interopDefault(import('../pages/user/review/index.vue' /* webpackChunkName: "pages/user/review/index" */))
const _f1fa3a0e = () => interopDefault(import('../pages/user/setting/index.vue' /* webpackChunkName: "pages/user/setting/index" */))
const _32ea653f = () => interopDefault(import('../pages/user/review/_id/edit/index.vue' /* webpackChunkName: "pages/user/review/_id/edit/index" */))
const _4d7b1db0 = () => interopDefault(import('../pages/wordle/index.vue' /* webpackChunkName: "pages/wordle/index" */))
const _cb9c43f4 = () => interopDefault(import('../pages/about/arenda/index.vue' /* webpackChunkName: "pages/about/arenda/index" */))
const _3ae32fc2 = () => interopDefault(import('../pages/about/contacts/index.vue' /* webpackChunkName: "pages/about/contacts/index" */))
const _06aba859 = () => interopDefault(import('../pages/about/corp/index.vue' /* webpackChunkName: "pages/about/corp/index" */))
const _0b719623 = () => interopDefault(import('../pages/about/delivery/index.vue' /* webpackChunkName: "pages/about/delivery/index" */))
const _73f76900 = () => interopDefault(import('../pages/about/guaranty/index.vue' /* webpackChunkName: "pages/about/guaranty/index" */))
const _1eed856c = () => interopDefault(import('../pages/about/howtobuy/index.vue' /* webpackChunkName: "pages/about/howtobuy/index" */))
const _4c37c6e0 = () => interopDefault(import('../pages/about/kredit/index.vue' /* webpackChunkName: "pages/about/kredit/index" */))
const _c9527566 = () => interopDefault(import('../pages/about/loyalty/index.vue' /* webpackChunkName: "pages/about/loyalty/index" */))
const _4b199618 = () => interopDefault(import('../pages/about/optom/index.vue' /* webpackChunkName: "pages/about/optom/index" */))
const _1b9f0f8e = () => interopDefault(import('../pages/about/personal-information/index.vue' /* webpackChunkName: "pages/about/personal-information/index" */))
const _f70edf1c = () => interopDefault(import('../pages/about/prepayment/index.vue' /* webpackChunkName: "pages/about/prepayment/index" */))
const _ab5b6994 = () => interopDefault(import('../pages/about/rassrochka/index.vue' /* webpackChunkName: "pages/about/rassrochka/index" */))
const _ec92583e = () => interopDefault(import('../pages/about/shippers/index.vue' /* webpackChunkName: "pages/about/shippers/index" */))
const _0ccc9006 = () => interopDefault(import('../pages/about/terms-of-use/index.vue' /* webpackChunkName: "pages/about/terms-of-use/index" */))
const _f5935f7a = () => interopDefault(import('../pages/brands/apple2023/index.vue' /* webpackChunkName: "pages/brands/apple2023/index" */))
const _03865767 = () => interopDefault(import('../pages/brands/xiaomi/index.vue' /* webpackChunkName: "pages/brands/xiaomi/index" */))
const _4d44af09 = () => interopDefault(import('../pages/city-select/script.js' /* webpackChunkName: "pages/city-select/script" */))
const _d78eef5a = () => interopDefault(import('../pages/nam20/activity/index.vue' /* webpackChunkName: "pages/nam20/activity/index" */))
const _6cbebade = () => interopDefault(import('../pages/nam20/game1/index.vue' /* webpackChunkName: "pages/nam20/game1/index" */))
const _5e855552 = () => interopDefault(import('../pages/nam20/game2/index.vue' /* webpackChunkName: "pages/nam20/game2/index" */))
const _192befda = () => interopDefault(import('../pages/nam20/game3/index.vue' /* webpackChunkName: "pages/nam20/game3/index" */))
const _ea696da8 = () => interopDefault(import('../pages/nam20/raffle/index.vue' /* webpackChunkName: "pages/nam20/raffle/index" */))
const _30feed22 = () => interopDefault(import('../pages/success-order/script.js' /* webpackChunkName: "pages/success-order/script" */))
const _6b8f99aa = () => interopDefault(import('../pages/about/delivery/dagestan/index.vue' /* webpackChunkName: "pages/about/delivery/dagestan/index" */))
const _46e7ed9c = () => interopDefault(import('../pages/about/delivery/moscow/index.vue' /* webpackChunkName: "pages/about/delivery/moscow/index" */))
const _689251b8 = () => interopDefault(import('../pages/about/delivery/other/index.vue' /* webpackChunkName: "pages/about/delivery/other/index" */))
const _282b64d0 = () => interopDefault(import('../pages/about/guaranty/moneyback/index.vue' /* webpackChunkName: "pages/about/guaranty/moneyback/index" */))
const _6f8d8bb4 = () => interopDefault(import('../pages/about/guaranty/returns/index.vue' /* webpackChunkName: "pages/about/guaranty/returns/index" */))
const _32f50d9b = () => interopDefault(import('../pages/about/guaranty/warrantly/index.vue' /* webpackChunkName: "pages/about/guaranty/warrantly/index" */))
const _e62732da = () => interopDefault(import('../pages/b/_id.vue' /* webpackChunkName: "pages/b/_id" */))
const _df6c0eb8 = () => interopDefault(import('../pages/brands/_id/index.vue' /* webpackChunkName: "pages/brands/_id/index" */))
const _7b52cb02 = () => interopDefault(import('../pages/order/_id.vue' /* webpackChunkName: "pages/order/_id" */))
const _07a8e980 = () => interopDefault(import('../pages/promo/_id.vue' /* webpackChunkName: "pages/promo/_id" */))
const _3500be3d = () => interopDefault(import('../pages/sales/_id.vue' /* webpackChunkName: "pages/sales/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/(catalog|pc-builds)/*/:id/review/create",
    component: _d8347a32,
    caseSensitive: true,
    name: "create-review-route"
  }, {
    path: "/brands/(xiaomi)/",
    component: _4e227b1a,
    caseSensitive: true,
    name: "brands-promo"
  }, {
    path: "/product/:id",
    component: _8e968990,
    redirect: "/",
    caseSensitive: true,
    name: "product-layout",
    children: [{
      path: "/(catalog|pc-builds)/*/:id(\\d+)/property",
      component: _4047ff94,
      name: "product-properties"
    }, {
      path: "/(catalog|pc-builds)/*/:id/question/:questionID",
      component: _63ae0cb9,
      name: "question-route"
    }, {
      path: "/(catalog|pc-builds)/*/:id(\\d+)/question",
      component: _230b7830,
      name: "product-question-list"
    }, {
      path: "/(catalog|pc-builds)/*/:id(\\d+)/accessory",
      component: _54effbe3,
      name: "product-accessories"
    }, {
      path: "/(catalog|pc-builds)/*/:id/review/:reviewID",
      component: _4f72a6c6,
      name: "review-route"
    }, {
      path: "/(catalog|pc-builds)/*/:id(\\d+)/review",
      component: _b9b472cc,
      name: "product-review-list"
    }, {
      path: "/(catalog|pc-builds)/*/:id(\\d+)",
      component: _03cd7a23,
      name: "product-index"
    }]
  }, {
    path: "/catalog/bonuses/",
    component: _23b0f4ca,
    caseSensitive: true,
    name: "catalog-bonuses"
  }, {
    path: "/catalog/(\\b[a-z0-9_-]+\\b)",
    component: _7dc990da,
    caseSensitive: true,
    name: "category-route"
  }, {
    path: "/catalog/",
    component: _2b2fe1e2,
    caseSensitive: true,
    name: "catalog-root-route"
  }, {
    path: "/catalog/*",
    component: _23b0f4ca,
    caseSensitive: true,
    name: "catalog-route"
  }, {
    path: "/personal/confirm/email/",
    component: _6f9675b8,
    caseSensitive: true,
    name: "confirm-user-email"
  }, {
    path: "/skidki/*",
    component: _f4c9f3b4,
    caseSensitive: true,
    name: "sale-route"
  }, {
    path: "/(e3r|r4r|t5r|y6r|u7r|i8r|o9r|q10r)",
    component: _4a93f774,
    caseSensitive: true,
    name: "rassrochka-promo"
  }, {
    path: "/404",
    component: _8e80517e,
    caseSensitive: true,
    name: "404"
  }, {
    path: "/about",
    component: _d8a8f054,
    caseSensitive: true,
    name: "about"
  }, {
    path: "/bonus",
    component: _3b3b1430,
    caseSensitive: true,
    name: "bonus"
  }, {
    path: "/bonus-activation",
    component: _5158691e,
    caseSensitive: true,
    name: "bonus-activation"
  }, {
    path: "/brand",
    component: _7c127f50,
    caseSensitive: true,
    name: "brand"
  }, {
    path: "/brands",
    component: _126ea239,
    caseSensitive: true,
    name: "brands"
  }, {
    path: "/cart",
    component: _07b969e6,
    caseSensitive: true,
    name: "cart"
  }, {
    path: "/catalog",
    component: _2b2fe1e2,
    caseSensitive: true,
    name: "catalog"
  }, {
    path: "/city-select",
    component: _0e2ca027,
    caseSensitive: true,
    name: "city-select"
  }, {
    path: "/compare",
    component: _1459a0a4,
    caseSensitive: true,
    name: "compare"
  }, {
    path: "/favorite",
    component: _357af589,
    caseSensitive: true,
    name: "favorite"
  }, {
    path: "/feedback",
    component: _09a08832,
    caseSensitive: true,
    name: "feedback"
  }, {
    path: "/friday",
    component: _866533e8,
    caseSensitive: true,
    name: "friday"
  }, {
    path: "/merch",
    component: _943e1cf0,
    caseSensitive: true,
    name: "merch"
  }, {
    path: "/nam20",
    component: _56a3593e,
    caseSensitive: true,
    name: "nam20"
  }, {
    path: "/newyear",
    component: _50af02b4,
    caseSensitive: true,
    name: "newyear"
  }, {
    path: "/pc-builder",
    component: _70d092ee,
    caseSensitive: true,
    name: "pc-builder"
  }, {
    path: "/privacy",
    component: _753932de,
    caseSensitive: true,
    name: "privacy"
  }, {
    path: "/product",
    component: _8e968990,
    caseSensitive: true,
    name: "product"
  }, {
    path: "/quiz2024",
    component: _4ed89d82,
    caseSensitive: true,
    name: "quiz2024"
  }, {
    path: "/r1",
    component: _5bc01fac,
    caseSensitive: true,
    name: "r1"
  }, {
    path: "/rassrochka",
    component: _4a93f774,
    caseSensitive: true,
    name: "rassrochka"
  }, {
    path: "/rst",
    component: _8db33ac8,
    caseSensitive: true,
    name: "rst"
  }, {
    path: "/sales",
    component: _27b31a95,
    caseSensitive: true,
    name: "sales"
  }, {
    path: "/search",
    component: _6cfe81d6,
    caseSensitive: true,
    name: "search"
  }, {
    path: "/stuff-hunt",
    component: _59fd898e,
    caseSensitive: true,
    name: "stuff-hunt"
  }, {
    path: "/success-order",
    component: _af2f0ae6,
    caseSensitive: true,
    name: "success-order"
  }, {
    path: "/user",
    component: _7f799735,
    caseSensitive: true,
    children: [{
      path: "",
      component: _152321f8,
      name: "user"
    }, {
      path: "achievements",
      component: _27824f1e,
      name: "user-achievements"
    }, {
      path: "address",
      component: _019c98c6,
      name: "user-address"
    }, {
      path: "assembly-save",
      component: _5466f3a6,
      name: "user-assembly-save"
    }, {
      path: "awaiting-review",
      component: _e805d63c,
      name: "user-awaiting-review"
    }, {
      path: "bonus",
      component: _80096cf0,
      name: "user-bonus"
    }, {
      path: "confirm-email",
      component: _6f9675b8,
      name: "user-confirm-email"
    }, {
      path: "history-bonus",
      component: _fec6cf62,
      name: "user-history-bonus"
    }, {
      path: "loyalty-level",
      component: _bc3bd5b4,
      name: "user-loyalty-level"
    }, {
      path: "order",
      component: _d6c87c12,
      name: "user-order"
    }, {
      path: "order-check",
      component: _4afe6832,
      name: "user-order-check"
    }, {
      path: "promocode",
      component: _6ee279a5,
      name: "user-promocode"
    }, {
      path: "review",
      component: _aa5ed5b6,
      name: "user-review"
    }, {
      path: "setting",
      component: _f1fa3a0e,
      name: "user-setting"
    }, {
      path: "review/:id/edit",
      component: _32ea653f,
      name: "user-review-id-edit"
    }]
  }, {
    path: "/wordle",
    component: _4d7b1db0,
    caseSensitive: true,
    name: "wordle"
  }, {
    path: "/about/arenda",
    component: _cb9c43f4,
    caseSensitive: true,
    name: "about-arenda"
  }, {
    path: "/about/contacts",
    component: _3ae32fc2,
    caseSensitive: true,
    name: "about-contacts"
  }, {
    path: "/about/corp",
    component: _06aba859,
    caseSensitive: true,
    name: "about-corp"
  }, {
    path: "/about/delivery",
    component: _0b719623,
    caseSensitive: true,
    name: "about-delivery"
  }, {
    path: "/about/guaranty",
    component: _73f76900,
    caseSensitive: true,
    name: "about-guaranty"
  }, {
    path: "/about/howtobuy",
    component: _1eed856c,
    caseSensitive: true,
    name: "about-howtobuy"
  }, {
    path: "/about/kredit",
    component: _4c37c6e0,
    caseSensitive: true,
    name: "about-kredit"
  }, {
    path: "/about/loyalty",
    component: _c9527566,
    caseSensitive: true,
    name: "about-loyalty"
  }, {
    path: "/about/optom",
    component: _4b199618,
    caseSensitive: true,
    name: "about-optom"
  }, {
    path: "/about/personal-information",
    component: _1b9f0f8e,
    caseSensitive: true,
    name: "about-personal-information"
  }, {
    path: "/about/prepayment",
    component: _f70edf1c,
    caseSensitive: true,
    name: "about-prepayment"
  }, {
    path: "/about/rassrochka",
    component: _ab5b6994,
    caseSensitive: true,
    name: "about-rassrochka"
  }, {
    path: "/about/shippers",
    component: _ec92583e,
    caseSensitive: true,
    name: "about-shippers"
  }, {
    path: "/about/terms-of-use",
    component: _0ccc9006,
    caseSensitive: true,
    name: "about-terms-of-use"
  }, {
    path: "/brands/apple2023",
    component: _f5935f7a,
    caseSensitive: true,
    name: "brands-apple2023"
  }, {
    path: "/brands/promo-brand",
    component: _4e227b1a,
    caseSensitive: true,
    name: "brands-promo-brand"
  }, {
    path: "/brands/xiaomi",
    component: _03865767,
    caseSensitive: true,
    name: "brands-xiaomi"
  }, {
    path: "/city-select/script",
    component: _4d44af09,
    caseSensitive: true,
    name: "city-select-script"
  }, {
    path: "/nam20/activity",
    component: _d78eef5a,
    caseSensitive: true,
    name: "nam20-activity"
  }, {
    path: "/nam20/game1",
    component: _6cbebade,
    caseSensitive: true,
    name: "nam20-game1"
  }, {
    path: "/nam20/game2",
    component: _5e855552,
    caseSensitive: true,
    name: "nam20-game2"
  }, {
    path: "/nam20/game3",
    component: _192befda,
    caseSensitive: true,
    name: "nam20-game3"
  }, {
    path: "/nam20/raffle",
    component: _ea696da8,
    caseSensitive: true,
    name: "nam20-raffle"
  }, {
    path: "/success-order/script",
    component: _30feed22,
    caseSensitive: true,
    name: "success-order-script"
  }, {
    path: "/about/delivery/dagestan",
    component: _6b8f99aa,
    caseSensitive: true,
    name: "about-delivery-dagestan"
  }, {
    path: "/about/delivery/moscow",
    component: _46e7ed9c,
    caseSensitive: true,
    name: "about-delivery-moscow"
  }, {
    path: "/about/delivery/other",
    component: _689251b8,
    caseSensitive: true,
    name: "about-delivery-other"
  }, {
    path: "/about/guaranty/moneyback",
    component: _282b64d0,
    caseSensitive: true,
    name: "about-guaranty-moneyback"
  }, {
    path: "/about/guaranty/returns",
    component: _6f8d8bb4,
    caseSensitive: true,
    name: "about-guaranty-returns"
  }, {
    path: "/about/guaranty/warrantly",
    component: _32f50d9b,
    caseSensitive: true,
    name: "about-guaranty-warrantly"
  }, {
    path: "/b/:id?",
    component: _e62732da,
    caseSensitive: true,
    name: "b-id"
  }, {
    path: "/brands/:id",
    component: _df6c0eb8,
    caseSensitive: true,
    name: "brands-id"
  }, {
    path: "/catalog/:id",
    component: _23b0f4ca,
    caseSensitive: true,
    name: "catalog-id"
  }, {
    path: "/category/:id?",
    component: _7dc990da,
    caseSensitive: true,
    name: "category-id"
  }, {
    path: "/order/:id?",
    component: _7b52cb02,
    caseSensitive: true,
    name: "order-id"
  }, {
    path: "/product/:id",
    component: _03cd7a23,
    caseSensitive: true,
    name: "product-id"
  }, {
    path: "/promo/:id?",
    component: _07a8e980,
    caseSensitive: true,
    name: "promo-id"
  }, {
    path: "/sales/:id",
    component: _3500be3d,
    caseSensitive: true,
    name: "sales-id"
  }, {
    path: "/skidki/:id?",
    component: _f4c9f3b4,
    caseSensitive: true,
    name: "skidki-id"
  }, {
    path: "/product/:id/accessory",
    component: _54effbe3,
    caseSensitive: true,
    name: "product-id-accessory"
  }, {
    path: "/product/:id/property",
    component: _4047ff94,
    caseSensitive: true,
    name: "product-id-property"
  }, {
    path: "/product/:id/question",
    component: _230b7830,
    caseSensitive: true,
    name: "product-id-question"
  }, {
    path: "/product/:id/review",
    component: _b9b472cc,
    caseSensitive: true,
    name: "product-id-review"
  }, {
    path: "/product/:id/review/create",
    component: _d8347a32,
    caseSensitive: true,
    name: "product-id-review-create"
  }, {
    path: "/product/:id/question/:questionID",
    component: _63ae0cb9,
    caseSensitive: true,
    name: "product-id-question-questionID"
  }, {
    path: "/product/:id/review/:reviewID",
    component: _4f72a6c6,
    caseSensitive: true,
    name: "product-id-review-reviewID"
  }, {
    path: "/",
    component: _2dfb1658,
    caseSensitive: true,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
