
/**
 * Основной слайдер акций для главной страницы десктопной версии, берет за основу компонент BaseInfiniteSlider и дополняет его навигацией
 */
export default {
  props: {
    /**
     * Список элементов слайдера, напрямую прокидывается внутрь BaseInfiniteSlider
     */
    list: {
      type: Array,
      required: true,
    },
    overflow: {
      type: String,
      default: 'hidden',
    },
    paddingBottom: {
      type: String,
      default: null,
    },
  },
}
