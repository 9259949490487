var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-slider",
    style: {
      overflow: _vm.overflow
    }
  }, [_c('BaseInfiniteSlider', {
    attrs: {
      "list": _vm.list,
      "align": "center",
      "offset-between-elements": 17,
      "padding-bottom": _vm.paddingBottom
    },
    on: {
      "current-slide": function ($event) {
        return _vm.$emit('current-slide', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var active = _ref.active,
            el = _ref.el;
        return [_vm._t("default", null, {
          "active": active,
          "el": el
        })];
      }
    }, {
      key: "nav",
      fn: function (_ref2) {
        var currentIndex = _ref2.currentIndex,
            slideCount = _ref2.slideCount,
            prev = _ref2.prev,
            next = _ref2.next,
            slideTo = _ref2.slideTo,
            isAutoplayActive = _ref2.isAutoplayActive;
        return [_c('div', {
          staticClass: "main-slider__nav-wrapper"
        }, [_c('div', {
          staticClass: "main-slider__left-arrow",
          on: {
            "click": function ($event) {
              return prev({
                resetAutoplay: true
              });
            }
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "main-slider__right-arrow",
          on: {
            "click": function ($event) {
              return next({
                resetAutoplay: true
              });
            }
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "main-slider__dots"
        }, [_c('div', {
          staticClass: "main-slider__dots-wrapper"
        }, _vm._l(slideCount, function (el) {
          return _c('div', {
            key: el,
            staticClass: "main-slider__dot",
            class: {
              'main-slider__dot_active': currentIndex === el - 1,
              'main-slider__dot_autoplay': isAutoplayActive
            },
            on: {
              "click": function ($event) {
                return slideTo({
                  index: el - 1
                });
              }
            }
          });
        }), 0)])])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }