import { render, staticRenderFns } from "./MainSlider.vue?vue&type=template&id=a9639cce&scoped=true&"
import script from "./MainSlider.vue?vue&type=script&lang=js&"
export * from "./MainSlider.vue?vue&type=script&lang=js&"
import style0 from "./MainSlider.vue?vue&type=style&index=0&id=a9639cce&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9639cce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInfiniteSlider: require('/app/components/sliders/BaseInfiniteSlider/BaseInfiniteSlider.vue').default})
