import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export interface CartItem {
  id?: number
  quantity: number
}

interface Cart {
  [key: number]: CartItem
}

export const useCartStore = defineStore('cart', () => {
  const data = ref<Cart>({})

  const cartIDArray = computed(() => {
    return data.value ? Object.keys(data.value) : []
  })

  const cartAmount = computed<number>(() => data.value
    ? Object.values(data.value).reduce((acc, product) => {
      return (acc += product.quantity)
    }, 0)
    : 0)

  function setList(list: Cart) {
    data.value = {
      ...data.value,
      ...list,
    }
  }

  function add({ id, payload }: { id: number; payload: CartItem }) {
    data.value = {
      ...data.value,
      [id]: payload,
    }
  }

  function remove({ id }: { id: number }) {
    const currentCart = { ...data.value }
    delete currentCart[id]
    data.value = currentCart
  }

  function reset(products?: { [key: number]: number }) {
    const currentProducts: Cart = {}
    if (products && Object.keys(products).length) {
      for (const key in products) {
        if (Object.prototype.hasOwnProperty.call(data.value, key))
          currentProducts[key] = data?.value?.[key]
      }
    }
    data.value = currentProducts
  }

  return {
    data,
    cartAmount,
    cartIDArray,
    setList,
    add,
    remove,
    reset,
  }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
