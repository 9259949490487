import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'
import type { Comment } from '~/type/Comment'

export default function (data: any): Comment {
  return {
    id: data.ID,
    productId: +data.UF_PRODUCT_ID,
    date: data.UF_DATE && format(parse(data.UF_DATE, 'd.LL.yyyy HH:mm:ss', new Date()), 'd MMMM yyyy', { locale: ru }),
    name: data.UF_USER_NAME,
    text: data.UF_TEXT || data.UF_REVIEW_TEXT,
    like: {
      count: +data.UF_POSITIVE_RATES || 0,
      active: Boolean(data.UF_LIKED),
    },
    dislike: {
      count: +data.UF_NEGATIVE_RATES || 0,
      active: Boolean(data.UF_DISLIKED),
    },
    rate: +data.UF_REVIEW_RATE || 0,
  }
}
